.video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Sesuaikan tinggi sesuai kebutuhan */
  overflow: hidden;
}

.video-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
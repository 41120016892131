/* Container untuk form */
.form-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Setiap step form */
.form-step {
  position: absolute;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
}

/* Step aktif */
.form-step-active {
  opacity: 1;
  z-index: 1;
  transform: translateX(0);
}

/* Animasi slide ke kanan */
.slide-right-enter {
  transform: translateX(100%);
}
.slide-right-enter-active {
  transform: translateX(0);
}

/* Animasi slide ke kiri */
.slide-left-enter {
  transform: translateX(-100%);
}
.slide-left-enter-active {
  transform: translateX(0);
}
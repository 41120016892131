.scroll-anim {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}
  
.scroll-anim-visible {
    opacity: 1;
    transform: translateX(0);
}

.backgroud-custom-1 {
    background-color: rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, rgba(159, 159, 159, 0.11) 25%, transparent 25%) -8px 0/ 16px 16px, linear-gradient(225deg, rgb(159, 159, 159, 0.18) 25%, transparent 25%) -8px 0/ 16px 16px, linear-gradient(315deg, rgba(159, 159, 159, 0.11) 25%, transparent 25%) 0px 0/ 16px 16px, linear-gradient(45deg, rgb(159, 159, 159, 0.18) 25%, rgba(255, 255, 255, 1) 25%) 0px 0/ 16px 16px;
}

.backgroud-custom-2 {
    background-color: rgba(255, 255, 255, 1);
    background: repeating-linear-gradient( -45deg, rgb(159, 159, 159, 0.1), rgb(159, 159, 159, 0.1) 2px, rgba(255, 255, 255, 1) 2px, rgba(255, 255, 255, 1) 10px );
}
